import React from 'react';

import { withAuthorization } from './session';

const AdminPage = () => (
  <div>
    <h1>Admin</h1>
    <p>The adming page is accessible by an admin user</p>
  </div>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AdminPage);