import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, TextField } from '@material-ui/core';

const useStyles = makeStyles({    
    
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        background: 'yellow',
        paddingTop: 20,
        paddingBottom: 20,
        alignContent: 'center',
        justifyContent: 'center',
        verticalAlign: 'center',
        margin: 'auto'
    },
    input: {
        width: "30%",
        minWidth: 250,    
        background: 'white',
    },
});

const SingleInputGrid = (props) =>  {

    const classes = useStyles();

    return (
        <Grid 
            className={classes.container}
            container
            direction="row">                 
            <TextField
                id={props.id}
                label={props.label}
                className={classes.input}
                placeholder={props.placeholder}
                onChange={props.onChange}
                type="text"
                name="keyword"
                margin="normal"
                variant="outlined">
            </TextField>
        </Grid>
    );    
}  

export default SingleInputGrid;