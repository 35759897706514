import React, { Fragment } from 'react';

import Navigation from "../components/navigation";

const Header = () => (
    <Fragment>
        <Navigation/>            
    </Fragment>
);

export default Header;