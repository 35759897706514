import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles({     
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        background: 'linear-gradient(45deg, #0052D4 30%, #4364F7 70%,#6FB1FC 100%)',
        paddingTop: 200,
        paddingBottom: 100,
        alignContent: 'center',
        justifyContent: 'center',
        margin: 'auto'
    },
    title: {
        width: '98%',
        color: 'yellow',
        fontFamily: 'Helvetica',
        fontWeight: 700,
        fontSize: 40,
        align: "center",
        textAlign: 'center'
    },
    description:{
        width: '98%',
        color: 'white',
        fontFamily: 'Roboto',
        fontWeight: 300,
        fontSize: 30,
        align: 'center',
        textAlign: 'center'
    }
});

const PageHeader = (props) =>  {

    const classes = useStyles();

    return (
        <Grid className={classes.container}
            container
            direction="row">
                <Typography className={classes.title}>
                    {props.title}
                </Typography>
                <Typography className={classes.description}>
                    {props.description}
                </Typography>
        </Grid>
    );    
}  

export default PageHeader;