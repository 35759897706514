import React from 'react';
import { AuthUserContext, withAuthorization } from './session';
import PasswordChangeForm from './change-password';
import SignOutButton from './sign-out';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({ 
  root:{
    display: 'flex',
    flexWrap: 'wrap',
    background: 'transparent',
    alignContent: 'space-around',
    justifyContent: "space-around"
  }, 
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    background: 'transparent',
    paddingTop: 200,
    paddingBottom: 100,
    alignContent: 'space-around',
    justifyContent: "space-around"
  }
});

const AccountPage = () => {

  const classes = useStyles();

  return (
  <AuthUserContext.Consumer>
    { authUser => (      
        <Grid className={classes.root}
            container
            direction="row">
            <Grid className={classes.container}
            container
            direction="row">
                <Typography>Account: {authUser.email}</Typography>
                <Typography>The Account Page is accessible by every signed in user.</Typography>
                <SignOutButton/>                
                <PasswordChangeForm/>
            </Grid>
        </Grid>    
    )}
  </AuthUserContext.Consumer>
  );
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);