export const suffixes = [
    {suffix: "aly", desc: "from Specially"},
    {suffix: "avy", desc: "from Savvy" },
    {suffix: "cient", desc: "from Proficient, Efficient, Sufficient" },
    {suffix: "cine", desc: "from Medicine"}, 
    {suffix: "diant", desc: "and Reliant"},
    {suffix: "duent", desc: "and Fluent"},
    {suffix: "emic", desc: "emic suffix"},
    {suffix: "err", desc: "From Super with double r"},
    {suffix: "ery", desc: "from Very"},
    {suffix: "eus", desc: "eus suffix"},
    {suffix: "ficient", desc: "from Efficient, Proficient, Sufficient"},
    {suffix: "fin", desc: "from Finance"},
    {suffix: "finic", desc: "finic suffix"},
    {suffix: "ful", desc: "ful suffix"},
    {suffix: "genic", desc: "genic suffix"},
    {suffix: "idy", desc: "from Tidy"},
    {suffix: "iest", desc: "from Easier, Happiest, Mightiest"},
    {suffix: "ios", desc: "ios suffix"},
    {suffix: "ius", desc: "from Genius"},
    {suffix: "lence", desc: "from Excellence"},
    {suffix: "lent", desc: "lent suffix"},
    {suffix: "leus", desc: "and Nucleus"},
    {suffix: "liant", desc: "from Reliant"},
    {suffix: "lux", desc: "from Luxury"},
    {suffix: "lytic", desc: "from Analytic"},
    {suffix: "mave", desc: "from Maven meaning Expert"},
    {suffix: "ment", desc: "from Sentiment"},
    {suffix: "moda", desc: "moda suffix"},
    {suffix: "mosh", desc: "mosh suffix"},
    {suffix: "mox", desc: "mox suffix"},
    {suffix: "neer", desc: "from Pioneer"},
    {suffix: "ness", desc: "from Happiness"},
    {suffix: "nific", desc: "nific suffix"},
    {suffix: "nius", desc: "and Genius"},
    {suffix: "nove", desc: "from Innovative"},
    {suffix: "ogy", desc: "from Technology, Analogy, -logy"},
    {suffix: "onix", desc: "onix suffix"},
    {suffix: "opia", desc: "from Utopia"},
    {suffix: "polis", desc: "polis suffix"},
    {suffix: "riant", desc: "riant suffix"},
    {suffix: "rify", desc: "rify suffix"},
    {suffix: "rio", desc: "rio suffix"},
    {suffix: "rious", desc: "and Industrious"},
    {suffix: "rist", desc: "from Purist"},
    {suffix: "rizon", desc: "from Horizon"},
    {suffix: "rific", desc: "from Terrific"},
    {suffix: "rius", desc: "rius suffix"},
    {suffix: "si", desc: "si suffix"},
    {suffix: "sity", desc: "from University"},
    {suffix: "sy", desc: "sy suffix"},
    {suffix: "tical", desc: "from Analytical"},
    {suffix: "tive", desc: "tive suffix"},
    {suffix: "topia", desc: "from Utopia"},
    {suffix: "trat", desc: "from Strategy"},
    {suffix: "trics", desc: "from Metrics"},
    {suffix: "vana", desc: "vana suffix"},
    {suffix: "vant", desc: "from Vantage, Advantage"},
    {suffix: "vel", desc: "from Marvel"},
    {suffix: "ver", desc: "from Ever"},
    {suffix: "vizon", desc: "vizon suffix"},
    {suffix: "vo", desc: "vo suffix"},
    {suffix: "zant", desc: "zant suffix"},
    {suffix: "zent", desc: "zent suffix"},
    {suffix: "zetic", desc: "zetic suffix"},
    {suffix: "zy", desc: "zy suffix"},
  ];
  
  