import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Paper, Button } from '@material-ui/core';

import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder'

const useStyles = makeStyles({
    card: {
      display: 'flex',
      flexWrap: 'wrap',
      width: "20%",
      minWidth: 300,
      minHeight: 200,
      marginBottom: 10,
      justifyContent: 'space-around', 
    },
    cardActionArea: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      padding: 0
    },
    cardContent: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      padding: 0 
    },
    paper:{
      display: 'flex',
      flexWrap: 'wrap',
      width: "100%",
      minWidth: 300,
      minHeight: 200,
      background: '#0052D4',
      alignContent: 'center',
      textAlign: "center",
      justifyContent: 'space-around', 
      verticalAlign: 'center',  
      padding: 0,
    },
    name: {
      width: '98%',
      color: 'yellow',
      fontFamily: 'Helvetica',
      fontWeight: 700,
      fontSize: 30,
      align: "center",
      textAlign: 'center',
      verticalAlign: 'center',
    },
    nameDescription:{
      width: '98%',
      color: 'white',
      fontFamily: 'Roboto',
      fontWeight: 300,
      fontSize: 24,
      align: 'center',
      textAlign: 'center'
    },
    icon: {
      fontSize: 32,
      color: 'orange'
    }
  });

  const Namecard = (props) => {

    const classes = useStyles();

    return (
      <Card className={classes.card}>
        <CardActionArea className={classes.cardActionArea}>
        <CardContent className={classes.cardContent}>
          <Paper className={classes.paper}
            elevation="0">
            <Typography className={classes.name}>
                {props.names}                          
            </Typography>
          </Paper>
        </CardContent>
        </CardActionArea>
        <CardActions>
          <Button 
            size="small"            
            color="primary">
            {props.names}.com
          </Button>
          <Button size="small" color="primary">
            {props.price}
          </Button>
          <FavoriteBorder className={classes.icon}/>
        </CardActions>         
      </Card>
    )
  }

/* const Namecard = (props) => {
  
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <Paper className={classes.paper}
          elevation="0">
          <FavoriteBorder className={classes.icon}/>
          <Typography className={classes.name}>
              {props.names}                          
          </Typography>
      </Paper>
      <Grid item xs={6}>
        <Link>
          {props.names}
        </Link>
      </Grid>
      <Grid item xs={6}>
      <FavoriteBorder className={classes.icon}/>
      </Grid>    
      
    </Grid>
  )
} */

export default Namecard;