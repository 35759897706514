import React from 'react';
import { 
    BrowserRouter as Router,
    Route,
} from 'react-router-dom';

import {compose} from 'recompose';
import * as ROUTES from '../constants/routes';
import { withAuthentication } from './session';
import { withFirebase } from './../firebase';

import HomePage from './home';
import SignUpPage from './sign-up';
import SignInPage from './sign-in';
import ForgotPasswordPage from './forgot-password';
import AccountPage from './account';
import AdminPage from './admin';
import NamesPage from './names-page';
import GeneratorPage from './generator-page';
import Header from './../layouts/header';

const App = () =>  (
    <Router>
        <div>
        <Header/>                
        <Route exact path={ROUTES.HOME} component={HomePage} />
        <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPasswordPage} />
        <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route exact path={ROUTES.ADMIN} component={AdminPage} />
        <Route exact path={ROUTES.NAMES} component={NamesPage} />
        <Route exact path={ROUTES.GENERATOR} component={GeneratorPage} />
        </div>
    </Router>
);

export default compose(withAuthentication,withFirebase)(App);