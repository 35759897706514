import { prefixes } from '../constants/prefixes';
import { suffixes } from '../constants/suffixes';
import { prepounders } from '../constants/prepounders';
import { compounders } from '../constants/compounders';

export function prefixer(keywords)
{
    let names = [];      

    for(let i=0; i < keywords.length; i++) {

        let prefixList = prefixes;

        for(let k = 0; k < prefixList.length; k++){        
            let prefixedName = prefixList[k].prefix + keywords[i];
            names = [...names, prefixedName];        
        }
    }

    return names;

}

export function suffixer(keywords)
{
    let names = [];      

    for(let i=0; i < keywords.length; i++) {

        let suffixList = suffixes;

        for(let k = 0; k < suffixList.length; k++){        
            let suffixedName = keywords[i] + suffixList[k].suffix;
            names = [...names, suffixedName];        
        }
    }

    return names;

}

export function prepounder(keywords)
{
    let names = [];      

    for(let i=0; i < keywords.length; i++) {

        let prepounderList = prepounders;

        for(let k = 0; k < prepounderList.length; k++){        
            let prepoundedName = prepounderList[k].prepounder + keywords[i];
            names = [...names, prepoundedName];        
        }
    }

    return names;

}

export function compounder(keywords)
{
    let names = [];      

    for(let i=0; i < keywords.length; i++) {

        let compounderList = compounders;

        for(let k = 0; k < compounderList.length; k++){        
            let compoundedName = keywords[i] + compounderList[k].compounder;
            names = [...names, compoundedName];        
        }
    }

    return names;

}