import React, { Component } from 'react';
import { withFirebase } from '../firebase';
import { 
    TextField,
    Grid,
    Typography,
    Button
  } from '@material-ui/core';
  import { withStyles } from '@material-ui/core/styles';
  
  const styles = {
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    textField: {
      minWidth: 250,
      width: "30%",
    },
    dense: {
      marginTop: 20,
    },
    menu: {
      width: "30%",
      minWidth: 250,
    },
    button: {
      marginTop: 20,
      width: "30%",
      minWidth: 250,
      height: 55,
      fontSize: 18
    }
  };

const INITIAL_STATE = {
    passwordOne: '',
    passwordTwo: '',
    error: null
};

class PasswordChangeForm extends Component {

    constructor(props){
        super(props);

        this.state = {...INITIAL_STATE};
    }

    onSubmit = event => {
        
        const { passwordOne } = this.state;

        this.props.firebase
          .doPasswordUpdate(passwordOne)
          .then(()=> {
              this.setState({...INITIAL_STATE});
          })
          .catch(error => {
              this.setState({error});
          })

        event.preventDefault();
    };

    onChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    onReset = event => {
        this.setState({error: null});
      };
    

    render(){

        const { passwordOne, passwordTwo, error} = this.state;
        const { classes } = this.props;
        const isDisabled = 
          passwordOne !== passwordTwo || passwordOne === '';

        return(
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center">
            <br/>
            <Typography variant="h4" component="h1">
                Change Password
            </Typography>
            <br/>
            {error && <p>{error.message}</p>}          
            <TextField
                id="passwordOne"
                name="passwordOne"
                label="New Password"
                className={classes.textField}
                type="password"
                autoComplete="current-password"
                margin="normal"
                variant="outlined"
                onChange={this.onChange}
                onClick={this.onReset}/>
            <TextField
                id="passwordTwo"
                name="passwordTwo"
                label="Confirm Password"
                className={classes.textField}
                type="password"
                autoComplete=""
                margin="normal"
                variant="outlined"
                onChange={this.onChange}
                onClick={this.onReset}/>    
            <Button 
                variant="contained"            
                color="primary"
                disabled={isDisabled}
                className={classes.button}            
                onClick={this.onSubmit} 
                type="submit">
                Change Password
            </Button>
          </Grid>
        );  
    }
}

export default withStyles(styles)(withFirebase(PasswordChangeForm));