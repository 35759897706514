import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({     
    
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: 20,
        paddingBottom: 100,
        alignContent: 'center',
        justifyContent: 'space-around',
        verticalAlign: 'center',
        margin: 'auto'
    },
});

const ListGrid = (props) =>  {

    const classes = useStyles();

    return (
        <Grid className={classes.container}
            container
            direction="row">
                {props.list}    
        </Grid>
    );    
}  

export default ListGrid;