import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {compose} from 'recompose';
import { withFirebase } from '../firebase';
import * as ROUTES from '../constants/routes';
import { 
  TextField,
  Grid,
  Typography,
  Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    minWidth: 250,
    width: "30%",
  },
  dense: {
    marginTop: 20,
  },
  menu: {
    width: "30%",
    minWidth: 250,
  },
  button: {
    marginTop: 20,
    width: "30%",
    minWidth: 250,
    height: 55,
    fontSize: 18
  }
};

const ForgotPasswordPage = () => (
  <div>
    <h1>Forgot Password</h1>
    <ForgotPasswordForm/>
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null
};

class ForgotPasswordFormBase extends Component {
  
  constructor(props){
    super(props);

    this.state = {...INITIAL_STATE};
  }

  onSubmit = event => {

    const {email} = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({...INITIAL_STATE});
      })
      .catch(error => {
        this.setState({error});
      });

      event.preventDefault();
  };

  onChange = event => {
    this.setState({[event.target.name]: event.target.value});
  };

  onReset = event => {
    this.setState({error: null});
  };


  render(){
    
    const { email, error } = this.state;
    const { classes } = this.props;
    const isDisabled = email === '';

    return(
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center">
        <br/>
        <Typography variant="h4" component="h1">
          Don't Worry!
        </Typography>
        <br/>
        <p>Enter your email address and we'll send you a link to reset your password</p>
        <br/>
        {error && <p>Incorrect email or password</p>}
        <TextField
            id="email"
            label="Email"
            className={classes.textField}
            type="email"
            name="email"
            value={email}
            autoComplete="email"
            margin="normal"
            variant="outlined"
            onChange={this.onChange}
            onClick={this.onReset}/>        
        <Button 
            variant="contained"            
            color="primary"
            disabled={isDisabled}
            className={classes.button}            
            onClick={this.onSubmit}>
          Reset Password
        </Button>
      </Grid>
    );
  }
}

const ForgotPasswordLink = () => (
  <p>
    Forgot your password? <Link to={ROUTES.FORGOT_PASSWORD}>Reset Password</Link>
  </p>
);

ForgotPasswordFormBase.propTypes = {
  classes: PropTypes.object.isRequired,
};

const ForgotPasswordForm = compose(
  withStyles(styles),
  withFirebase,
)(ForgotPasswordFormBase);

export default ForgotPasswordPage;

export {ForgotPasswordForm, ForgotPasswordLink};
