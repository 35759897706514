import React from 'react';
import { Link } from 'react-router-dom';

import { 
    AppBar,
    Toolbar,
    Button,
    IconButton,
    MenuItem
} from '@material-ui/core';

import * as ROUTES from '../constants/routes';
import {AuthUserContext} from './session';
import ToolbarMenu from './toolbar-menu';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = {
    root: {
      flexGrow: 1
    },
    grow: {
      flexGrow: 1
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
      fontSize: 20
    },
    button: {
      fontSize: 18
    }  
};

const Navigation = () => (
    <div>
        <AuthUserContext.Consumer>
            {authUser =>
                authUser ? <NavigationAuth classes={styles}/> : <NavigationNonAuth classes={styles}/>}
        </AuthUserContext.Consumer> 
    </div>
);        

const NavigationAuth = (props) => {
    
    const { classes } = props;

    const logo = process.env.PUBLIC_URL+'/assets/img/namevise.png';

    return (
        <AppBar position="fixed"
                color="white">            
            <Toolbar>
            <Link to={ROUTES.HOME} color="inherit" style={{ textDecoration: 'none'}}>
                    <IconButton color="inherit" aria-label="Menu">
                        <img src={logo} alt="Namevise" className="img-xxs"/>
                    </IconButton>
                </Link>
                <ToolbarMenu
                render={collapsed => {
                    return collapsed
                    ? [
                        <div>
                        <Link to={ROUTES.NAMES} style={{ textDecoration: 'none', display: 'block' }}>
                            <MenuItem autoclose={true}
                            className={classes.menuButton}>
                                Names
                            </MenuItem>
                        </Link>
                        <Link to={ROUTES.GENERATOR} style={{ textDecoration: 'none', display: 'block' }}>
                            <MenuItem autoclose={true}
                            className={classes.menuButton}>
                                Generator
                            </MenuItem>
                        </Link>
                        <Link to={ROUTES.ACCOUNT} style={{ textDecoration: 'none', display: 'block' }}>
                            <MenuItem autoclose={true}
                            className={classes.menuButton}>
                                Account
                            </MenuItem>
                        </Link>
                        </div>
                      ]
                    : [
                        <div>
                        <Button
                            key="login"
                            color="primary"
                            href={ROUTES.NAMES}
                            className={classes.button}>
                            Names
                        </Button>
                        <Button
                            key="login"
                            color="primary"
                            href={ROUTES.GENERATOR}
                            className={classes.button}>
                            Generator
                        </Button>
                        <Button
                            key="login"
                            color="primary"
                            href={ROUTES.ACCOUNT}
                            className={classes.button}>
                            Account
                        </Button>
                        </div>
                      ];
                }}
                />
            </Toolbar>
        </AppBar>        
    );
}

const NavigationNonAuth = (props) => {
    
    const { classes } = props;

    const logo = process.env.PUBLIC_URL+'/assets/img/namevise.png';

    return (
        <AppBar position="fixed" 
                color="white">            
            <Toolbar>
                <Link to={ROUTES.HOME} color="inherit" style={{ textDecoration: 'none'}}>
                    <IconButton color="inherit" aria-label="Menu">
                        <img src={logo} alt="Namevise" className="img-xxs"/>
                    </IconButton>
                </Link>                
                <ToolbarMenu
                render={collapsed => {
                    return collapsed
                    ? [
                        <Link to={ROUTES.SIGN_IN} style={{ textDecoration: 'none', display: 'block' }}>
                            <MenuItem autoclose={true}
                            className={classes.menuButton}>
                                Sign In
                            </MenuItem>
                        </Link>
                      ]
                    : [
                        <Button
                            color="primary"
                            href={ROUTES.SIGN_IN}
                            className={classes.button}>
                            Sign In
                        </Button>
                      ];
                }}
                />
            </Toolbar>
        </AppBar>        
    );
}

Navigation.propTypes = {
    classes: PropTypes.object.isRequired
};

/*
const condition = authUser => !!authUser;

export default compose(
    withAuthorization(condition),
    withStyles(styles)
    )(Navigation);
*/
export default withStyles(styles)(Navigation);