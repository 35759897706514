import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';

const useStyles = makeStyles({    
    
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        background: 'yellow',
        paddingBottom: 20,
        alignContent: 'center',
        justifyContent: 'center',
        verticalAlign: 'center',
        margin: 'auto'
    },
    button: {
        width: "30%",
        height: 60,
        minWidth: 250,
        fontSize: 18
    },
});

const SingleButtonGrid = (props) =>  {

    const classes = useStyles();

    return (
        <Grid 
            className={classes.container}
            container
            direction="row">                 
            <Button 
                variant="contained"            
                color="primary"
                onClick={props.onClick}
                className={classes.button}>
                    {props.buttonLabel}
            </Button>
        </Grid>
    );    
}  

export default SingleButtonGrid;