import React from 'react';
import { FirebaseContext } from '../firebase';
import HomeBody from './../layouts/home-body';

const HomePage = () => (
  <FirebaseContext.Consumer>
    {firebase => {
      return (
       <div>
         <HomeBody/>
       </div>
      );
    }}
  </FirebaseContext.Consumer>
);

export default HomePage;