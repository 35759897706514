import React, {Component} from 'react';
import { Link, withRouter} from 'react-router-dom';
import {compose} from 'recompose';

import { withFirebase } from '../firebase';
import * as ROUTES from '../constants/routes';
import { 
  TextField,
  Grid,
  Typography,
  Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    minWidth: 250,
    width: "30%",
  },
  dense: {
    marginTop: 20,
  },
  menu: {
    width: "30%",
    minWidth: 250,
  },
  button: {
    marginTop: 20,
    width: "30%",
    minWidth: 250,
    height: 55,
    fontSize: 18
  }
};

const SignUpPage = () => (
  <div>
    <h1>Sign Up</h1>
      <SignUpForm/>
  </div>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class SignUpFormBase extends Component {
  
  constructor(props){
    super(props);

    this.state = INITIAL_STATE;
  }

  onSubmit = event => {
    const {
      username,
      email,
      passwordOne
    } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email,passwordOne)
      .then(authUser => {
        //Create a new user in your firebase realtime database
        return this.props.firebase
          .user(authUser.user.uid)
          .set({
            username,
            email,
          });
      })
      .then(authUser => {
        this.setState({...INITIAL_STATE});
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({error});
      })

    event.preventDefault();
  }

  onChange = event => {
    this.setState({[event.target.name]: event.target.value});
  }

  onReset = event => {
    this.setState({error: null});
  }


  render(){

    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const isDisabled =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === ''; 

    const { classes } = this.props;

      return(
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center">
          <br/>
          <Typography variant="h4" component="h1">
            Join Namevise
          </Typography>
          <br/>
          {error && <p>{error.message}</p>}
          <TextField
              id="username"
              name="username"
              label="Username"
              className={classes.textField}
              type="text"
              value={username}
              autoComplete="username"
              margin="normal"
              variant="outlined"
              onChange={this.onChange}
              onClick={this.onReset}/>
          <TextField
              id="email"
              name="email"
              label="Email"
              className={classes.textField}
              type="email"
              value={email}
              autoComplete="email"
              margin="normal"
              variant="outlined"
              onChange={this.onChange}
              onClick={this.onReset}/>
          <TextField
              id="passwordOne"
              name="passwordOne"
              label="Password"
              className={classes.textField}
              type="password"
              autoComplete="current-password"
              margin="normal"
              variant="outlined"
              onChange={this.onChange}
              onClick={this.onReset}/>
           <TextField
              id="passwordTwo"
              name="passwordTwo"
              label="Confirm Password"
              className={classes.textField}
              type="password"
              autoComplete=""
              margin="normal"
              variant="outlined"
              onChange={this.onChange}
              onClick={this.onReset}/>    
          <Button 
              variant="contained"            
              color="primary"
              disabled={isDisabled}
              className={classes.button}            
              onClick={this.onSubmit} 
              type="submit">
            Sign Up
          </Button>
        </Grid>  
    ) 
  }
}

SignUpFormBase.propTypes = {
  classes: PropTypes.object.isRequired,
};

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
)

const SignUpForm = compose(
  withRouter,
  withStyles(styles),
  withFirebase,
)(SignUpFormBase)

export default SignUpPage;

export {SignUpForm, SignUpLink};