import React from 'react';

import { withFirebase } from './../firebase';
import { 
    Button
  } from '@material-ui/core'; 

const SignOutButton = ({firebase}) => (
    <Button 
      variant="contained"            
      color="primary"         
      onClick={firebase.doSignOut}>
      Sign Out
    </Button>
);

export default withFirebase(SignOutButton);