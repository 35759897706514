import React from 'react';
import { compose } from 'recompose';
import { AuthUserContext, withAuthorization } from './session';

import { Grid } from '@material-ui/core';
import { withFirebase } from '../firebase';

import PageHeader from './reusables/page-header';
import ListGrid from './reusables/list-grid';
import Namecard from './reusables/name-card';

const NamesPage = ({firebase}) => {

  const title = "The Best Names";
  const description = "Explore a curated list of highly brandable, meaningful and creative names";

  const names = ['Convercient',
                 'Evernove',
                 'Fincient',
                 'Finacient',
                 'Lexavy',
                 'Livelyness',
                 'Mavvel',
                 'Nexarion',
                 'Nexavy',
                 'Novacient',
                 'Priovant',
                 'Swoorn',
                 'TechMave',
                 'Tenacient',
                 'Twinklys',
                 'Voqer',
                 'Voxavi',
                 'Yences',
                 'Zalleo',
                 'Zencient',
                 'Zounty'
                ];               

  const nameList = names.map((names) => 
              <Namecard names={names} price="$1,299"/>
  );

  return (
      <AuthUserContext.Consumer>
        { authUser => (
          <Grid container
              display='flex'
              flexWrap='wrap'
              background='transparent'
              alignContent='center'
              justifyContent='space-around'
              verticalAlign='center'
              margin='auto'
              direction="row">              
              <PageHeader title={title}
                  description={description} />
              <ListGrid list={nameList}/>
          </Grid>
        )}
      </AuthUserContext.Consumer>
    ); 
 }

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
)(NamesPage);