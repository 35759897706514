import React, { Component } from 'react';
import { AuthUserContext, withAuthorization } from './session';

import { Grid } from '@material-ui/core';

import PageHeader from './reusables/page-header';
import ListGrid from './reusables/list-grid';
import SingleButtonGrid from './reusables/single-button-grid';
import SingleInputGrid from './reusables/single-input-grid';
import Namecard from './reusables/name-card';

import { prefixer, suffixer, prepounder, compounder } from '../generator/combiner';

class GeneratorPage extends Component
{  
    constructor(props){
      super(props);

      this.state = {
        names: [],
        keywords: []
      }

      this.buildInput = this.buildInput.bind(this);
      this.generateNames = this.generateNames.bind(this);
    }

    buildInput(event){
      
      //clear previous entries
      this.setState(state => ({
        keywords: []
      }));

      let keyword = event.target.value;

      //trim spaces
      keyword = keyword.replace(/\s+/g, '');

      //comma separated keywords
      this.setState(state => ({
        keywords: keyword.split(",")
      }));

    }
    
    generateNames() {

      let generatedNames = [];
      let prefixedNames = [];
      let suffixedNames = [];
      let prepoundedNames = [];
      let compoundedNames = [];
     
      prefixedNames = [...prefixedNames,...prefixer(this.state.keywords)];
      suffixedNames = [...suffixedNames,...suffixer(this.state.keywords)];
      prepoundedNames = [...prepoundedNames,...prepounder(this.state.keywords)];
      compoundedNames = [...compoundedNames,...compounder(this.state.keywords)];

      generatedNames = [...generatedNames,...prefixedNames];
      generatedNames = [...generatedNames,...suffixedNames];
      generatedNames = [...generatedNames,...prepoundedNames];
      generatedNames = [...generatedNames,...compoundedNames];

      this.setState(state => ({
        names: generatedNames
      }));
    }

    showNamePage(event) {

    }

    render(){

      const title = "The Best Names";
      const description = "Explore a curated list of highly brandable, meaningful and creative names";

      let names = this.state.names;

      const nameList = names.map((names) => 
              <Namecard names={names} buttonLabel="Register" onClick={this.showNamePage}/>);

      return (
        <AuthUserContext.Consumer>
          { authUser => (
            <Grid container
                display='flex'
                flexWrap='wrap'
                background='transparent'
                alignContent='center'
                justifyContent='space-around'
                verticalAlign='center'
                margin='auto'
                direction="row">
                <PageHeader 
                    title={title}
                    description={description} />
                <SingleInputGrid 
                    id="keyword"
                    label="Keyword" 
                    placeholder="Keyword1, Keyword2,..."
                    onChange={this.buildInput} />
                <SingleButtonGrid buttonLabel="Generate Names" onClick={this.generateNames}/>               
                <ListGrid list={nameList}/>
            </Grid>
          )}
        </AuthUserContext.Consumer>
      );
    }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(GeneratorPage);