export const compounders = [
    {compounder: "Base", desc: "from Proficient, Efficient, Sufficient" },
    {compounder: "Block", desc: "from Technology, Analogy, -logy"},
    {compounder: "Boss", desc: "from Technology, Analogy, -logy"},
    {compounder: "Burst", desc: "from Technology, Analogy, -logy"},
    {compounder: "Cadet", desc: "from Technology, Analogy, -logy"},
    {compounder: "Comet", desc: "from Technology, Analogy, -logy"},
    {compounder: "Crest", desc: "from Technology, Analogy, -logy"},
    {compounder: "Crown", desc: "from Technology, Analogy, -logy"},
    {compounder: "Forth", desc: "from Savvy" },
    {compounder: "Habit", desc: "from Technology, Analogy, -logy"},
    {compounder: "Jolt", desc: "from Technology, Analogy, -logy"},
    {compounder: "Metric", desc: "from Technology, Analogy, -logy"},
    {compounder: "Ocean", desc: "from Technology, Analogy, -logy"},
    {compounder: "Optic", desc: "from Technology, Analogy, -logy"},
    {compounder: "Pipe", desc: "from Technology, Analogy, -logy"},
    {compounder: "Pledge", desc: "from Technology, Analogy, -logy"},
    {compounder: "Savvy", desc: "from Technology, Analogy, -logy"},
    {compounder: "Stack", desc: "from Technology, Analogy, -logy"},
    {compounder: "Streak", desc: "from Technology, Analogy, -logy"},
    {compounder: "Stream", desc: "from Technology, Analogy, -logy"},
    {compounder: "Tide", desc: "from Technology, Analogy, -logy"},
    {compounder: "Vibe", desc: "from Technology, Analogy, -logy"},
    {compounder: "Wield", desc: "from Technology, Analogy, -logy"},
    {compounder: "Yield", desc: "from Technology, Analogy, -logy"},
];