import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'recompose';

import { SignUpLink } from './sign-up';
import { ForgotPasswordLink } from './forgot-password';
import { withFirebase } from '../firebase';
import * as ROUTES from '../constants/routes';
import { 
  TextField,
  Grid,
  Typography,
  Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    minWidth: 250,
    width: "30%",
  },
  dense: {
    marginTop: 20,
  },
  menu: {
    width: "30%",
    minWidth: 250,
  },
  button: {
    marginTop: 20,
    width: "30%",
    minWidth: 250,
    height: 55,
    fontSize: 18
  }
};

const SignInPage = () => (
  <div>
    <h1>Sign In</h1>
    <SignInForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null
};

class SignInFormBase extends Component {
    
  constructor(props){
    super(props);

    this.state = {...INITIAL_STATE};
  }

  onSubmit = event => {
    const {email, password} = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email,password)
      .then(()=> {
        this.setState({...INITIAL_STATE});
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({error});
      });

    event.preventDefault();
  };

  onChange = event => {    
    this.setState({[event.target.name]: event.target.value});   
  };

  onReset = event => {
    this.setState({error: null});
  };

  render() {
        
    const {email,password,error} = this.state;

    const { classes } = this.props;

    const isDisabled = (email === "" || password === "");

    return(
      <Grid className={classes.container}
        container
        direction="column"
        justify="center"
        alignItems="center">
        <br/>
        <Typography variant="h4" component="h1">
          Welcome Back!
        </Typography>
        <br/>
        {error && <p>Incorrect email or password</p>}
        <TextField
            id="email"
            label="Email"
            className={classes.textField}
            type="email"
            name="email"
            value={email}
            autoComplete="email"
            margin="normal"
            variant="outlined"
            onChange={this.onChange}
            onClick={this.onReset}/>
        <TextField
            id="password"
            label="Password"
            name="password"
            className={classes.textField}
            type="password"
            margin="normal"
            value={password}
            variant="outlined"            
            onChange={this.onChange}
            onClick={this.onReset}/>
        <Button 
            variant="contained"            
            color="primary"
            disabled={isDisabled}
            className={classes.button}            
            onClick={this.onSubmit}>
          Sign In
        </Button>
        <SignUpLink />
        <ForgotPasswordLink/> 
      </Grid>      
    );
  }
}

SignInFormBase.propTypes = {
  classes: PropTypes.object.isRequired,
};

const SignInForm = compose(
  withRouter,
  withStyles(styles),
  withFirebase,
)(SignInFormBase);

export default SignInPage;

export {SignInForm};