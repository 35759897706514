import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import * as ROUTES from '../constants/routes';

const useStyles = makeStyles({ 
  root:{
    display: 'flex',
    flexWrap: 'wrap',
    background: 'transparent',
    alignContent: 'space-around',
    justifyContent: "space-around"
  }, 
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    background: 'linear-gradient(45deg, #0052D4 30%, #4364F7 70%,#6FB1FC 100%)',
    paddingTop: 200,
    paddingBottom: 100,
    alignContent: 'space-around',
    justifyContent: "space-around"
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: "60%",
    minWidth: 300,
    backgroundColor: "transparent",
    textAlign: "center",    
    justifyContent: 'space-around', 
    paddingBottom: 30
  },
  tagline: {
    width: "100%",
    color: 'yellow',
    fontFamily: "Helvetica",
    fontWeight: 700,
    fontSize: 60,
    align: "center",
    textAlign: 'center'
  },
  tagline2:{
    width: "98%",
    color: 'white',
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: 30,
    align: "center"
  },
  button: {
    backgroundColor: "yellow",
    width: "45%",
    maxWidth: 300,
    fontSize: 20,
    color: "black",
    height: 60,
  }
});

const HomeBody = () => {

    const classes = useStyles();

    return (
        <Grid className={classes.root}
            container
            direction="row">
            <Grid className={classes.container}
                container
                direction="column">
                  <Paper className={classes.paper}
                        elevation="0">
                        <Typography 
                            className={classes.tagline}>
                           We'll Name It
                        </Typography>
                        <Typography 
                            className={classes.tagline2}>
                            Find great names or Generate nice ones.
                        </Typography>
                    </Paper>
                    <Paper className={classes.paper}
                        elevation="0">
                        <Button 
                            variant="contained"            
                            color="primary"
                            href={ROUTES.NAMES}
                            className={classes.button}>
                            Find
                        </Button>
                        <Button 
                            variant="contained"            
                            color="primary"
                            href={ROUTES.GENERATOR}
                            className={classes.button}>
                            Generate
                        </Button>
                    </Paper>                    
            </Grid>                                 
        </Grid>
    );
}

export default HomeBody;